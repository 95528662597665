<template>
  <div class="out-box">
    <left-box></left-box>
    <div class="right-box">
      <div class="inputs">
        <input-text :title="'Email'" v-model="mail" ref="mail" valid-rule="email"></input-text>
        <input-pwd :title="'Password'" v-model="pwd" ref="pwd"></input-pwd>
        <agree-protocol v-model="agreeProtocol"></agree-protocol>
        <a-button class="my-login" @click="onLogin" :loading="loading"><span>Log in</span></a-button>
        <div class="my-links flex-row-start-between">
          <a-link class="my-link" @click="toResetPassword">Forgot Password?</a-link>
          <a-link class="my-link my-link2" @click="toRegister">Sign Up</a-link>
        </div>
        <div class="el-icon-eye"></div>
        <div class="el-icon-eye-open"></div>
      </div>
    </div>
  </div>
</template>

<script>
import InputPwd from "@/components/InputPwd";
import InputText from "@/components/InputText";
import AgreeProtocol from "@/components/AgreeProtocol";

export default {
  components: {
    InputPwd,
    InputText,
    AgreeProtocol
  },
  data() {
    return {
      redirect: null,
      mail: '',
      pwd: '',
      agreeProtocol: false,
      loading: false
    }
  },
  created() {
    // console.log('login page created! $route.query:', JSON.stringify(this.$route.query))
    this.redirect = this.$route.query.redirect
  },
  computed: {},
  methods: {
    onLogin() {
      console.log('onLogin', this.mail, this.pwd)
      let validResult = true
      validResult &= this.$refs.mail.validText()
      validResult &= this.$refs.pwd.validPwd()
      if (!this.agreeProtocol) {
        this.$message.warning({
          content: 'You must accept the Terms of Use and Privacy Policy to log in an A4x account',
        })
        validResult = false
      }
      if (!validResult) return
      this.loading = true
      const loginForm = {
        email: this.mail.trim(),
        password: this.pwd.trim()
      }
      this.$store.dispatch('user/login', loginForm).then(() => {
        this.$router.push({path: this.redirect || '/'})
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    toRegister() {
      this.$router.push({path: '/register'})
    },
    toResetPassword() {
      this.$router.push({path: '/reset-password'})
    }
  }
}
</script>

<style lang="scss" scoped src="./design.scss"></style>
